.image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
}

.filter:after {
    content: "";
    height: 50px;
    background: #d0d0d0;
    position: absolute;
    width: 100%;
    opacity: 0.7;
}

.filter {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: -47px;
}

.filter span {
    z-index: 1;
}

.image-container:hover .filter {
    animation: actionPanelToBottom 0.5s ease forwards;
}

@keyframes actionPanelToBottom {
    from {top: -47px}
    to {top: 10px}
}
